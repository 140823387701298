<template>
  <div class="vx-row" v-if="formShow">
    <div class="vx-col w-full text-center">
      <p class="text-lg">One Time Customer Data</p>
      <hr />
    </div>
    <div class="vx-col sm:w-1/2 w-full mb-base">
      <div class="vx-row my-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Customer Name <span class="text-danger">*</span></label>
          <vs-input class="w-full" v-model="oneTimeDataForm.name" placeholder="Input name" name="Customer Name" :readonly="oneTimeDataForm.readonly"/>
        </div>
      </div>
      <address-component
        :data="oneTimeDataForm"
        :source="'sr'"
        @data="updateDataOnetime"
      ></address-component>
        <!-- @data="setDataAddress" -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Ship / Bill Address <span class="text-danger">*</span></label>
          <vs-input class="w-full" v-model="oneTimeDataForm.address" placeholder="Re-input address" :readonly="oneTimeDataForm.readonly"/>
        </div>
      </div>
    </div>
    <div class="vx-col sm:w-1/2 w-full mb-base">
      <div class="vx-row my-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Contact Person <span class="text-danger">*</span></label>
          <vs-input class="w-full" v-model="oneTimeDataForm.contact_person" placeholder="Input contact person" :readonly="oneTimeDataForm.readonly"/>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">NIK <span class="text-danger">*</span></label>
          <vs-input class="w-full" v-model="oneTimeDataForm.nik" placeholder="Input NIK" :readonly="oneTimeDataForm.readonly"/>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Phone Number <span class="text-danger">*</span></label>
          <vs-input class="w-full" v-model="oneTimeDataForm.phone" placeholder="Input phone number" :readonly="oneTimeDataForm.readonly"/>
        </div>
      </div>

      <!-- Domisili -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Domisili*</label>
          <vs-input type="text" class="w-full" v-model="oneTimeDataForm.domisili" placeholder="Input domisili" :readonly="oneTimeDataForm.readonly"/>
        </div>
      </div>
      
      <!-- NPWP Type -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">NPWP Type*</label>
          <vs-input type="text" class="w-full" v-model="oneTimeDataForm.npwp_type" placeholder="Input npwp type" :readonly="oneTimeDataForm.readonly" />
        </div>
      </div>

      <!-- NPWP -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">NPWP*</label>
          <vs-input type="number" class="w-full" v-model="oneTimeDataForm.npwp" placeholder="Input npwp" :readonly="oneTimeDataForm.readonly" />
        </div>
      </div>

      <!-- NPWP Name -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">NPWP Name*</label>
          <vs-input type="text" class="w-full" v-model="oneTimeDataForm.npwp_name" placeholder="Input npwp name" :readonly="oneTimeDataForm.readonly" />
        </div>
      </div>

      <!-- NITKU -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">NITKU<span class="text-danger">*</span></label>
          <vs-input type="text" class="w-full" v-model="oneTimeDataForm.nitku" placeholder="Input nitku" :readonly="oneTimeDataForm.readonly" />
          <span class="text-danger text-sm" v-show="errors.has('nitku')"> {{ errors.first("nitku") }} </span>
        </div>
      </div>

      <!-- SPPKP -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">SPPKP*</label>
          <vs-input type="text" class="w-full" v-model="oneTimeDataForm.sppkp" placeholder="Input sppkp" :readonly="oneTimeDataForm.readonly" />
        </div>
      </div>

      <!-- Tax Address -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Tax Address*</label>
          <vs-input type="text" class="w-full" v-model="oneTimeDataForm.tax_address" placeholder="Input tax address" :readonly="oneTimeDataForm.readonly" />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button size="medium" v-on:click="handleCustOnetime" color="primary">
            {{ this.oneTimeDataForm.id >0 ? "Update" : "Confirm" }}
          </vs-button>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import AddressComponent from "@/components/master/Address";

export default {
  components: {
    AddressComponent
  },
  props: {
    onetimeCustomerData:{
      type: Object,
      default:()=>{},
    },
    isOneTime:{
      type:Boolean,
      default:false,
    },
  },
  mounted() {
  },
  methods: {
    getOnetimeCustomer() {
      this.$vs.loading();
      let params = {
        territory_id: this.onetimeCustomerData.territory_id
      }
      this.$http.get("/api/v1/master/customer-onetime", { params: params,}).then(resp => {
        if (resp.code == 200) {
          this.oneTimeDataForm = {
            id:this.onetimeCustomerData.id,
            territory_id:this.onetimeCustomerData.territory_id,
            name:resp.data.records.name,
            contact_person:"",
            nik:resp.data.records.nik,
            nitku:resp.data.records.nitku,
            phone:resp.data.records.phone,
            domisili:resp.data.records.domisili,
            npwp_type:resp.data.records.npwp_type,
            npwp:resp.data.records.npwp,
            npwp_name:resp.data.records.npwp_name,
            sppkp:resp.data.records.sppkp,
            tax_address:resp.data.records.tax_address,
            address :resp.data.address_records.address,
            postalCode :resp.data.records.postal_code,
            countryName :resp.data.records.country,
            provinceName :resp.data.records.province,
            cityName :resp.data.records.city,
            districtName :resp.data.records.district,
            subdistrictName :resp.data.records.sub_district,
            readonly :this.onetimeCustomerData.readonly,
          }
          this.formShow = true
          this.$emit("data",{
            status:"onetime",
            data:this.oneTimeDataForm,
            data_ori:resp.data,
          });
        } else {
          this.$emit("data",{
            status:"failed",
            data:this.oneTimeDataForm,
          });
          let message ="Failed to get Customer"
          if (resp.message!="") {
            message = resp.message
          }
          this.$vs.notify({
            title: "Error",
            text: message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
        }
        this.$vs.loading.close();
      })
    },
    getOnetimeCustomerByID() {
      this.$vs.loading();
      this.$http.get("/api/v1/master/customer-onetime-by-id",{
          params: {
            id:this.onetimeCustomerData.id
          }
        }).then(resp => {
        if (resp.code == 200) {
          console.log("customer one time data",resp.data.records)
          this.oneTimeDataForm = {
            id:this.onetimeCustomerData.id,
            territory_id:this.onetimeCustomerData.territory_id,
            name:resp.data.records.name,
            contact_person:resp.data.records.contact_person,
            nik:resp.data.records.nik,
            nitku:resp.data.records.nitku,
            phone:resp.data.records.phone,
            domisili:resp.data.records.domisili,
            npwp_type:resp.data.records.npwp_type,
            npwp:resp.data.records.npwp,
            npwp_name:resp.data.records.npwp_name,
            sppkp:resp.data.records.sppkp,
            tax_address:resp.data.records.tax_address,
            address :resp.data.records.shipto_address,
            postalCode :resp.data.records.postal_code,
            countryName :resp.data.records.country,
            provinceName :resp.data.records.province,
            cityName :resp.data.records.city,
            districtName :resp.data.records.district,
            subdistrictName :resp.data.records.sub_district,
            readonly :this.onetimeCustomerData.readonly,
          }
          this.formShow = true
          this.$emit("data", {
            status:"inv-onetime",
            data:this.oneTimeDataForm,
          });
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to get Customer",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle"
          });
          this.$emit("data",{
            status:"failed",
            data:this.oneTimeDataForm,
          });
        }
        this.$vs.loading.close();
      });
    },
    handleCustOnetime(){
      /*
        Save temporary UI data Onetime Customer
        Check apakah form terisi dengan benar? jika ya :
        1. set column field Customer Name
        2. set column field Bill to
        3. Set column field Ship to
      */

      let form_empty = false;
      // Buat save customer onetime disini, ke tabel baru
      form_empty = (this.oneTimeDataForm.name == null || this.oneTimeDataForm.name == "") || form_empty;
      form_empty = (this.oneTimeDataForm.contact_person == null || this.oneTimeDataForm.contact_person == "") || form_empty;
      form_empty = (this.oneTimeDataForm.nik == null || this.oneTimeDataForm.nik == "") || form_empty;
      form_empty = (this.oneTimeDataForm.phone == null || this.oneTimeDataForm.phone == "") || form_empty;
      if (!this.validateFormOneTime()) {
        return
      }
      if(form_empty){
        this.$vs.notify({
          title: "Error",
          text: "Please fill form correctly !",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle"
        });
        return;
      }

      if (this.oneTimeDataForm.npwp&&(this.oneTimeDataForm.npwp.length != 15 && this.oneTimeDataForm.npwp.length != 16)) {
      this.$vs.notify({
        color: "danger",
        title: "Error",
        text: "NPWP must be 15 or 16 characters!",
        position: "top-right",
        iconPack: "feather",
        icon: "icon-alert-circle",
      });
      return;
    }
    if (!this.oneTimeDataForm.nik||(this.oneTimeDataForm.nik&&this.oneTimeDataForm.nik.length != 16)) {
      this.$vs.notify({
        color: "danger",
        title: "Error",
        text: "required 16 characters for NIK!",
        position: "top-right",
        iconPack: "feather",
        icon: "icon-alert-circle",
      });
      return;
    }

      // this.selectedCustomer.name = this.onetimeCustomerData.name;
      this.$vs.notify({
        color: "success",
        title: "Success",
        text: "Set customer data success",
        position: "top-right",
        iconPack: "feather",
        icon: "icon-check-circle",
      });
      this.$emit("data",{
            status:"btn-update-data",
            data:{
              name:this.oneTimeDataForm.name,
              address :this.oneTimeDataForm.address,
              contact_person:this.oneTimeDataForm.contact_person,
              readonly :this.onetimeCustomerData.readonly,
            },
          });
      // window.scrollTo(0,0);
      // this.handleCloseOt();

    },
    validateFormOneTime(){
      let isvalid = true
      this.errors.remove("nitku");
      if (this.oneTimeDataForm.nitku != null && this.oneTimeDataForm.nitku != "") {
        if (this.oneTimeDataForm.nitku.trim() == "" || this.oneTimeDataForm.nitku.trim().length != 22) {
          this.errors.add({
            field: "nitku",
            msg: "The NITKU field must be 22 digits.",
          });
          isvalid = false
          this.$vs.notify({
              color: "danger",
              title: "Error",
              text: "The NITKU field must be 22 digits.",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
        }
      } else {
        if (this.oneTimeDataForm.nitku == null || this.oneTimeDataForm.nitku == "") {
          this.errors.add({
            field: "nitku",
            msg: "The NITKU field is required.",
          });
          isvalid = false
          this.$vs.notify({
              color: "danger",
              title: "Error",
              text: "The NITKU field is required.",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
        }
      }
      return isvalid
    },
    updateDataOnetime(dt){
      let dataComp ={
        id:this.onetimeCustomerData.id,
        territory_id:this.onetimeCustomerData.territory_id,
        name:this.oneTimeDataForm.name,
        address:this.oneTimeDataForm.address,
        contact_person:this.oneTimeDataForm.contact_person,
        nik:this.oneTimeDataForm.nik,
        nitku:this.oneTimeDataForm.nitku,
        phone:this.oneTimeDataForm.phone,
        domisili:this.oneTimeDataForm.domisili,
        npwp_type:this.oneTimeDataForm.npwp_type,
        npwp:this.oneTimeDataForm.npwp,
        npwp_name:this.oneTimeDataForm.npwp_name,
        sppkp:this.oneTimeDataForm.sppkp,
        tax_address:this.oneTimeDataForm.tax_address,
        postalCode :"",
        countryName :"",
        provinceName :"",
        cityName :"",
        districtName :"",
        subdistrictName :"",
      }
      if (dt) {
        dataComp.postalCode = dt.postalCode
        dataComp.countryName = dt.countryName
        dataComp.provinceName = dt.provinceName
        dataComp.cityName = dt.cityName
        dataComp.districtName = dt.districtName
        dataComp.subdistrictName = dt.subdistrictName
      }
      this.$emit("data",{
            status:"update-data",
            data:dataComp,
          });
    },
    resetForm(){
      this.oneTimeDataForm = {
        id:0,
				territory_id:0,
        name:null,
        contact_person:null,
        nik:null,
        nitku:null,
        phone:null,
        domisili:null,
        npwp_type:null,
        npwp:null,
        npwp_name:null,
        sppkp:null,
        tax_address:null,
        address :null,
        postalCode :null,
        countryName :null,
        provinceName :null,
        cityName :null,
        districtName :null,
        subdistrictName :null,
      }
      this.formShow = false
      this.$emit("reset");
    }
  },
  watch: {
    "isOneTime":{
      handler:function(val){
        if (val) {
          console.log("this.onetimeCustomerData child",this.onetimeCustomerData)
          if (this.onetimeCustomerData.id>0) {
            this.oneTimeDataForm.readonly = true;
            this.getOnetimeCustomerByID();
          }else{
            this.oneTimeDataForm.readonly = false
            this.getOnetimeCustomer();
          }
        }else{
          this.resetForm()
        }
      },
    },
    "oneTimeDataForm.name" :{
      handler: function(val) {
        console.log("name val, ",val)
        this.updateDataOnetime();
      }
    },
    "oneTimeDataForm.address" :{
      handler: function(val) {
        console.log("address val, ",val)
        this.updateDataOnetime();
      }
    },
    "oneTimeDataForm.contact_person" :{
      handler: function(val) {
        console.log("contact_person val, ",val)
        this.updateDataOnetime();
      }
    },
    "oneTimeDataForm.nik" :{
      handler: function(val) {
        console.log("nik val, ",val)
        this.updateDataOnetime();
      }
    },
    "oneTimeDataForm.phone" :{
      handler: function(val) {
        console.log("phone val, ",val)
        this.updateDataOnetime();
      }
    },
    "oneTimeDataForm.domisili" :{
      handler: function(val) {
        console.log("domisili val, ",val)
        this.updateDataOnetime();
      }
    },
    "oneTimeDataForm.npwp_type" :{
      handler: function(val) {
        console.log("npwp_type val, ",val)
        this.updateDataOnetime();
      }
    },
    "oneTimeDataForm.npwp" :{
      handler: function(val) {
        console.log("npwp val, ",val)
        this.updateDataOnetime();
      }
    },
    "oneTimeDataForm.npwp_name" :{
      handler: function(val) {
        console.log("npwp_name val, ",val)
        this.updateDataOnetime();
      }
    },
    "oneTimeDataForm.sppkp" :{
      handler: function(val) {
        console.log("sppkp val, ",val)
        this.updateDataOnetime();
      }
    },
    "oneTimeDataForm.tax_address" :{
      handler: function(val) {
        console.log("tax_address val, ",val)
        this.updateDataOnetime();
      }
    },
    "oneTimeDataForm.nitku" :{
      handler: function(val) {
        console.log("nitku val, ",val)
        this.errors.remove("nitku");
        if (this.oneTimeDataForm.nitku != null && this.oneTimeDataForm.nitku != "") {
          if (this.oneTimeDataForm.nitku.trim() == "" || this.oneTimeDataForm.nitku.trim().length != 22) {
            this.errors.add({
              field: "nitku",
              msg: "The NITKU field must be 22 digits.",
            });
          }
        } else {
          if (this.oneTimeDataForm.nitku == null || this.oneTimeDataForm.nitku == "") {
            this.errors.add({
              field: "nitku",
              msg: "The NITKU field is required.",
            });
          }
        }
        this.updateDataOnetime();
      }
    },
  },
  data(){
    return{
      formShow:false,
      oneTimeDataForm:{
        id:0,
        territory_id:0,
        name:null,
        contact_person:null,
        nik:null,
        nitku:null,
        phone:null,
        domisili:null,
        npwp_type:null,
        npwp:null,
        npwp_name:null,
        sppkp:null,
        tax_address:null,
        address :null,
        postalCode :null,
        countryName :null,
        provinceName :null,
        cityName :null,
        districtName :null,
        subdistrictName :null,
        readonly:false,
      },
    }
  },
};
</script>
